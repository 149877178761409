export function flattenObject(obj, parent = "", result = {}) {
  for (let key in obj) {
    let propName = parent ? parent + "." + key : key;
    if (typeof obj[key] == "object") {
      flattenObject(obj[key], propName, result);
    } else {
      result[propName] = obj[key];
    }
  }
  return result;
}

export const checkObjectDeepEquality = (obj1, obj2) => {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }

    if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
      if (obj1[key].length !== obj2[key].length) {
        return false;
      }

      const sortedArr1 = [...obj1[key]].sort((a, b) => (a.id > b.id ? 1 : -1));
      const sortedArr2 = [...obj2[key]].sort((a, b) => (a.id > b.id ? 1 : -1));

      if (!checkObjectDeepEquality(sortedArr1, sortedArr2)) {
        return false;
      }
    } else {
      if (!checkObjectDeepEquality(obj1[key], obj2[key])) {
        return false;
      }
    }
  }

  return true;
};

export const deepEqualInArray = (obj1, array1) => {
  for (let obj2 of array1) {
    if (checkObjectDeepEquality(obj1, obj2)) {
      return true;
    }
  }

  return false;
};

export function ellipses(message, len = 15) {
  let result = message;

  if (typeof result != "string") {
    return null;
  }

  if (result.length > len) {
    return result.slice(0, len) + "...";
  }

  return result;
}
