import React, { useState } from "react";
import FilterDropDownSelectInputController from "./FilterDropDownSelectInput/FilterDropDownSelectInput.controller";
import {
  ClearButton,
  FiltersDiv,
  DefaultFilterButton,
} from "./JobFilters.styles";
import { isiPhone } from "@/utils/commonUtils";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import ToggleSwitch from "@/outscal-commons-frontend/Styled/ToggleSwitch";
import { isMobile } from "@/hooks/useMobileBreakPoint";

const JobFiltersView = ({
  filterUIObj,
  filters,
  clearFilters,
  disableFiltersClear = false,
  setFilters,
  setFiltersInQuery,
  hasjobCategoryTypeFilter,
  hasFreeFilter,
  onToggleClick,
  enableGamingModeFilter = false,
  enableFreeCourses = false,
  hideHeader = false,
  styleOverrides = {},
}) => {
  const [showFilters, setShowFilters] = useState(!isiPhone());

  const handleShowFilters = () => {
    setShowFilters(true);
  };
  return isMobile && !showFilters ? (
    <FiltersDiv>
      <DefaultFilterButton onClick={handleShowFilters}>
        Show Filters
      </DefaultFilterButton>
    </FiltersDiv>
  ) : (
    <FiltersDiv
      isIphone={isiPhone()}
      hideHeader={hideHeader}
      style={styleOverrides}
    >
      {/* {enableGamingModeFilter && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ToggleSwitch
            analyticsText={`gaming_job_${hasjobCategoryTypeFilter}`}
            checked={hasjobCategoryTypeFilter}
            onChange={onToggleClick}
            checkedText="GAME"
            uncheckedText="ALL"
            borderRadius="6px"
          />
          <p
            style={{
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            J&nbsp;O&nbsp;B&nbsp;S
          </p>
        </div>
      )} */}
      {enableFreeCourses && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ToggleSwitch
            analyticsText={`free_courses_filter`}
            checked={hasFreeFilter}
            onChange={onToggleClick}
            borderRadius="6px"
          />
          <p
            style={{
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            F&nbsp;R&nbsp;E&nbsp;E
          </p>
        </div>
      )}
      {filterUIObj.map((item) => {
        return (
          <FilterDropDownSelectInputController
            setFilters={setFilters}
            setFiltersInQuery={setFiltersInQuery}
            filters={filters[item.name] || []}
            key={item.name}
            name={item.name}
            label={item.label}
            optionsList={item.optionsList}
            defaultList={item.defaultList}
            customData={item.customData}
            filterAppliedLabel={item.filterAppliedLabel}
            width="300px"
            clearFilters={clearFilters}
          />
        );
      })}

      <ClearButton
        disableFiltersClear={disableFiltersClear}
        onClick={() => {
          clearFilters();
        }}
        data-analytics={[UIElements.BUTTON, "clear_job_filters"]}
      >
        Clear
      </ClearButton>
    </FiltersDiv>
  );
};

export default JobFiltersView;
