import React from "react";
import MobileDropDownView from "./MobileDropDown.view";
import { useRouter } from "next/router";
import UTMManager from "@/Logic/ClientLogic/Services/UTMManager";

const MobileDropDownController = ({
  width,
  label,
  value,
  customData,
  onOptionClick,
  onClear,
  maxWidth,
  setSearchTerm,
  searchTerm,
  displayOptions,
  active,
  closeIfOpen,
  name,
}) => {
  const router = useRouter();

  const isPaidLead = () => {
    return (
      UTMManager?.instance?.sessionUtm?.utm_medium === "paid" &&
      (router.pathname === "/courses" ||
        router.pathname.includes("/learning-paths") ||
        router.asPath.includes("/game-development-courses") ||
        router.pathname === "/course/[courseSlug]")
    );
  };

  return (
    <MobileDropDownView
      width={width}
      label={label}
      value={value}
      customData={customData}
      onClear={onClear}
      onOptionClick={onOptionClick}
      maxWidth={maxWidth}
      setSearchTerm={setSearchTerm}
      searchTerm={searchTerm}
      hasFiltersApplied={value && value.length > 0}
      displayOptions={displayOptions}
      active={active}
      closeIfOpen={closeIfOpen}
      name={name}
      isPaidLead={isPaidLead}
    />
  );
};

export default MobileDropDownController;
